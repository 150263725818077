import { FC, SyntheticEvent, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { useMount } from 'react-use';
import _ from "underscore";

import s from './VideoPage.module.scss';
import { useStore } from 'store';
import { videoQuestions } from 'store/data';
import { StepInitial, StepTitle, StepVideo, StepQuestion, StepAnswer } from './Steps';
import { STEPS } from './enums';
import { PAGE_TRANSITION } from 'durations';
import DevButtons from './DevButtons';

export const VideoPage: FC = () => {
  const navigate = useNavigate();
  const params = useParams();
  const {questions, setQuestions, questionNo, setQuestionNo, score, setScore, setAppFrameTitle, setHideFrameBar, hideFrameBar, answers, setAnswers} = useStore();
  
  const videoRef = useRef<HTMLVideoElement>(null);
  const [step, setStep] = useState<number>(0);
  const [hasSeenVideoPreIncident, setHasSeenVideoPreIncident] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<number>(-1);
  const [currentVideoDuration, setCurrentVideoDuration] = useState<number>(0);
  const [isFirstVideoPlay, setIsFirstVideoPlay] = useState<boolean>(true);

  // temporary useEffect for dev so questions array is never empty
  useEffect(() => {
    if(_.isEmpty(questions)) {
      setQuestions(_.sample(videoQuestions, 3))
    }
  }, [questions, videoQuestions, setQuestions]);

  useMount(() => {
    if(parseInt(params.questionNo!, 10) === 0) {
      // reset answers and score on first question
      setAnswers([]);
      setScore(0);
    }
    setQuestionNo(parseInt(params.questionNo!, 10));
  });

  useEffect(() => {
    if(step === STEPS.INITIAL_SCREEN) {
      if(!_.isEmpty(questions)) {
        setAppFrameTitle(`${questionNo + 1} / ${questions.length}`);
      }

      if(hideFrameBar) {
        setHideFrameBar(false);
      }
    }

    if(step === STEPS.VIDEO_PRE_INCIDENT) {
      if(videoRef.current) {
        videoRef.current.currentTime = 0;
        videoRef.current.play();
      }
    }

    if(step === STEPS.VIDEO_INCIDENT) {
      if(videoRef.current) {
        videoRef.current.currentTime = questions[questionNo].pauseAt;
        videoRef.current.play();
      }
    }
  }, [step]);

  const handleInitialAnimationEnd = () => {
    setStep(STEPS.TITLE_SCREEN);
  }

  const handleVideoStart = () => {
    setStep(STEPS.VIDEO_PRE_INCIDENT)
  }

  const handleOnPlaying = (e: SyntheticEvent<HTMLVideoElement>) => {
		// currentVideoDuration is NaN on first load
		if(!currentVideoDuration) {
      const target = e.target as HTMLVideoElement;
      console.log(target)
			setCurrentVideoDuration(target.duration);
		}
	}

  const handleTimeUpdate = (e: SyntheticEvent<HTMLVideoElement>) => {
    const target = e.target as HTMLVideoElement;
    if(step === STEPS.VIDEO_PRE_INCIDENT) {
      if(target.currentTime >= questions[questionNo].pauseAt) {
        target.pause();
        setHasSeenVideoPreIncident(true);
        setStep(hasSeenVideoPreIncident ? STEPS.QUESTION : STEPS.WHATS_YOUR_CALL);
      }
    }
  }

  const handleOnEnded = (e: SyntheticEvent<HTMLVideoElement>) => {
    setStep(STEPS.ANSWER);
  }

  const handleVideoReplay = () => {
    setIsFirstVideoPlay(false);
    handleVideoStart();
  }

  const handleOptionClick = (index: number) => {
    if(selectedOption < 0) {
      const tempAnswers = [] as number[];
      tempAnswers.push(...answers, index);

      setAnswers(tempAnswers);
      setSelectedOption(index);
      setStep(STEPS.VIDEO_INCIDENT);
    }
  }
  
  const handleNext = () => {
    navigate('/your-score');
  }

  if(_.isEmpty(questions)) {
    return null
  } else {
    return (
      <AnimatePresence exitBeforeEnter>
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ ease: "easeOut", duration: PAGE_TRANSITION / 1000 }}
          className={s.videoPageWrap}
        >
          {
            (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') && <DevButtons step={step} setStep={setStep} />
          }
          {
            step === STEPS.INITIAL_SCREEN && (
              <StepInitial 
                onAnimationEnd={handleInitialAnimationEnd} 
              />
            )
          }
          {
            step === STEPS.TITLE_SCREEN && (
              <StepTitle
                tournament={questions[questionNo].tournament}
                game={questions[questionNo].game} 
                handleVideoStart={handleVideoStart}
              />
            )
          }
          {
            (step === STEPS.WHATS_YOUR_CALL || step === STEPS.QUESTION) && (
              <StepQuestion
                step={step}
                questionOptions={questions[questionNo].options}
                selectedOption={selectedOption}
                handleOptionClick={handleOptionClick}
                handleVideoReplay={handleVideoReplay}
                onWhatsYourCallAnimationEnd={() => setStep(STEPS.QUESTION)}
                isFirstVideoPlay={isFirstVideoPlay}
              />
            )
          }
          {
            step === STEPS.ANSWER && (
              <StepAnswer
                isCorrect={selectedOption > -1 && questions[questionNo].options[selectedOption].isCorrect}
                verdict={questions[questionNo].verdict}
                handleNext={handleNext}
              />
            )
          }
          {
            step !== STEPS.INITIAL_SCREEN && (
              <StepVideo
                step={step}
                videoSrc={questions[questionNo].videoUrl}
                videoRef={videoRef}
                handleOnPlaying={handleOnPlaying}
                handleOnEnded={handleOnEnded}
                handleTimeUpdate={handleTimeUpdate}
              />
            )
          }
        </motion.div>
      </AnimatePresence>
    )
  }
}