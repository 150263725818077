import { HEADER_DELAY } from "durations";

export const backgroundOutlineTextVariants = {
  initial: {
    opacity: 0.25,
    scale: 1,
    x: '100%'
  },
  start: {
    opacity: 1,
    x: '-5%',
    transition: {
      ease: "easeOut",
      duration: 1.1
    }
  },
  end: {
    opacity: 0,
    transition: {
      ease: "easeOut",
      delay: HEADER_DELAY / 1000,
      duration: 0.75
    }
  }
}