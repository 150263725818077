import { FC, useCallback, useState } from "react";
import { Formik, FormikHelpers } from "formik";
import { Button, ButtonTypes, CrossIcon, Input, InputColorTypes, InputTypes, Popup, TickIcon } from "@omm.agency/irecomponents";
import * as Yup from 'yup';
import { useMount } from "react-use";
import { motion } from "framer-motion";
import fetchJsonp from "fetch-jsonp";

import s from './SignUp.module.scss';
import { resetState, useStore } from "store";
import { useNavigate } from "react-router-dom";

type formData = {
  name: string;
  email: string
}

export const SignUp: FC = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [success, setSuccess] = useState<null | boolean>(null);
  const [errorMsg, setErrorMsg] = useState('');
  const navigate = useNavigate();
  const { setHideFrameBar } = useStore();

  useMount(() => {
    setHideFrameBar(false);
  });

  const handleSubmit = useCallback(async (
    values: formData,
    { setSubmitting }: FormikHelpers<formData>
  ) => {
    // const response = fetch(process.env.REACT_APP_MAILCHIMP_URL);
    const rawUrl = process.env.REACT_APP_MAILCHIMP_URL?.replace(/\/$/, "") || "";
    const subscribeUrl = `${rawUrl}/subscribe/post-json?u=${process.env.REACT_APP_LIST_FORM}&id=${process.env.REACT_APP_LIST_ID}&subscribe=Subscribe&EMAIL=${values.email}&${process.env.REACT_APP_LIST_NAME_FIELD_ID}=${values.name}&c=JSONP_CALLBACK`;
    await fetchJsonp(subscribeUrl, {
      jsonpCallback: 'c',
    })
      .then(response => response.json())
      .then(json => {
        console.log(json);
        if (json.result === 'error' && json.msg.indexOf('too many recent signup requests') === -1) {
          setSuccess(false);
          console.log(errorMsg);
          setErrorMsg(json.msg);
          return;
        }
        setSuccess(true);
      })
      .catch((ex) => {
        console.log(ex);
        setSuccess(false);
      });
    setShowPopup(true);
    setSubmitting(false);
  }, [setShowPopup, setSuccess, success]);

  const handleComplete = useCallback(() => {
    resetState();
    navigate('/');
  }, [resetState]);

  return (
    <>
      <Formik
        initialValues={{ name: '', email: '' }}
        onSubmit={handleSubmit}
        validationSchema={Yup.object({
          name: Yup.string().min(2, 'Please provide your name').required('Please provide your name'),
          email: Yup
            .string()
            .email('Please enter a valid email address')
            .required('Please provide an email address')
        })}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          errors,
          touched,
          isSubmitting
        }) => (
          <form className={s.wrap} onSubmit={handleSubmit}>
            <div className={s.formInputs}>
              <h1>SIGN UP TO CLAIM<br />
                YOUR PRIZE</h1>
              <p data-size-large>Please enter your e-mail address if you wish to stay up to date with news, offers and events from IRE, and to claim your discount code.</p>
              <Input
                name="name"
                type={InputTypes.TEXT}
                variant={InputColorTypes.COLOR_BLACK}
                onChange={handleChange}
                onBlur={handleBlur}
                isValid={(touched.name && !errors.name)}
                hasError={!!(touched.name && errors.name)}
                errorText={errors.name}
                placeholder="Enter Name"
                className={s.input}
              />
              <Input
                name="email"
                type={InputTypes.TEXT}
                variant={InputColorTypes.COLOR_BLACK}
                onChange={handleChange}
                onBlur={handleBlur}
                isValid={(touched.email && !errors.email)}
                hasError={!!(touched.email && errors.email)}
                errorText={errors.email}
                placeholder="Enter Email"
                className={s.input}
              />
            </div>
            <Button
              type={ButtonTypes.SKILLS}
              content="Submit"
              disabled={isSubmitting}
              isLarge
            />
          </form>
        )}
      </Formik>
      {(success === true || success === false) && (
        <motion.div className={s.exitButton} onClick={handleComplete}>
          <CrossIcon width={16} height={16} strokeWidth={4} />
          <span className={s.appFrameExitLabel}>Exit</span>
        </motion.div>
      )}
      <Popup
        isVisible={showPopup}
        closePopup={() => {
          setShowPopup(false);
        }}
        disableOverlayClickClose
        hasTransparentContent
        overlayOpacity={1}
      >
        {(success || success === false) && (
          <div className={s.formStatus}>
            {success && (
              <>
                <div className={s.successIcon}>
                  <TickIcon />
                </div>
                <h2>DETAILS SUBMITTED</h2>
              </>
            )}
            {success === false && (
              <>
                <CrossIcon width={60} height={60} className={s.errorIcon} />
                <h2>OOPS SOMETHING WENT WRONG</h2>
                <p>{errorMsg}</p>
                <Button
                  content="Try Again"
                  onClick={() => { setShowPopup(false) }}
                  type={ButtonTypes.SKILLS}
                  isLarge
                />
              </>
            )}
          </div>
        )}
      </Popup>
    </>
  );
}
