import { Routes, Route, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';

import { AppFrameLocal } from 'components';

import {
  YourScore,
  Home,
  HowToPlay,
  VideoPage,
  ThankYou,
  Terms,
  Error,
  SignUp
} from '../pages/index';

export default function AppRoutes() {
  const location = useLocation();

  return (
    <AnimatePresence exitBeforeEnter initial={false}>
      <Routes location={location} key={location.pathname}>
        <Route path='*' element={<Error />} />
        <Route path='/' element={<Home />} />
        <Route path='/terms' element={<AppFrameLocal><Terms /></AppFrameLocal>} />
        <Route path='/how-to-play' element={<AppFrameLocal><HowToPlay /></AppFrameLocal>} />
        <Route path='/video/:questionNo' element={<AppFrameLocal><VideoPage /></AppFrameLocal>} />
        <Route path='/your-score' element={<AppFrameLocal><YourScore /></AppFrameLocal>} />
        <Route path='/thank-you' element={<AppFrameLocal><ThankYou /></AppFrameLocal>} />
        <Route path='/sign-up' element={<AppFrameLocal><SignUp /></AppFrameLocal>} />
      </Routes>
    </AnimatePresence>
  );
}
