import { FC } from "react";
import { Checkbox } from "@omm.agency/irecomponents";

import s from "./LabelledCheckbox.module.scss";

interface BaseProps {
  onChange: () => void;
  checked: boolean;
  text: string;
}

type LabelledCheckboxProps = BaseProps;

export const LabelledCheckbox: FC<LabelledCheckboxProps> = ({ onChange, checked, text }) => {
  return <div className={s.wrap}>
    <Checkbox onChange={onChange} checked={checked} />
    <p data-size-large onClick={onChange}>{text}</p>
  </div>
}
