import { FC, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useMount } from 'react-use';
import {
  Button,
  ButtonTypes,
  ButtonWidths,
  ConfigContext,
  ConfigContextType,
} from '@omm.agency/irecomponents';

import s from './Home.module.scss';
import { PAGE_TRANSITION } from 'durations';
import { resetState } from 'store';

export const Home: FC = () => {
  const navigate = useNavigate();
  const { staticContentUrl } = useContext<ConfigContextType>(ConfigContext);

  useMount(() => {
    // always reset state on load
    resetState();
  });

  const handlePlay = () => {
    navigate('/terms');
  };

  const getVideoSrc = (videoName: string) => `${staticContentUrl}/${videoName}`;

  return (
    <motion.div
      initial={{ opacity: 1 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ ease: 'easeOut', duration: PAGE_TRANSITION / 1000 }}
      className={s.homeWrap}
      onClick={handlePlay}
    >
      <h1>Be the Referee</h1>
      <div className={s.homeContent}>
        <p data-size-large>
          Watch footage from real match moments and become the referee.
          <br />
          Taking part brings you closer to securing discounted tickets to the
          International Rugby Experience.
        </p>
        <Button
          type={ButtonTypes.SKILLS}
          width={ButtonWidths.NORMAL}
          content='Tap to Start'
          onClick={handlePlay}
          isLarge
        />
      </div>
      <video
        playsInline
        muted={true}
        loop={true}
        controls={false}
        autoPlay={true}
        className={s.homeVideo}
        src={getVideoSrc('home.mp4')}
      ></video>
    </motion.div>
  );
};
