export const videoQuestions = [
  {
    difficulty: 1,
    tournament: '2019 Rugby World Cup',
    game: 'Japan vs Scotland',
    videoUrl: 'Easy-Japan-vs-Scotland.mp4',
    thumbnailUrl: '/images/Easy-Japan-vs-Scotland.png',
    pauseAt: 8.8,
    options: [
      {
        text: 'Try Scored & 5 points to Scotland',
        isCorrect: false,
      },
      {
        text: 'Forward Pass & scrum to Japan',
        isCorrect: true,
      },
    ],
    verdict:
      'This was a forward pass so the try was not awarded. Scrum awarded to Japan.',
  },
  // {
  //   difficulty: 1,
  //   tournament: '2019 Rugby World Cup',
  //   game: 'Ireland vs Russia',
  //   videoUrl: 'Easy-Ireland-vs-Russia.mp4',
  //   thumbnailUrl: '/images/Easy-Ireland-vs-Russia.png',
  //   pauseAt: 12.1,
  //   options: [
  //     {
  //       text: 'Try Scored & 5 points to Ireland',
  //       isCorrect: false,
  //     },
  //     {
  //       text: 'Forward Pass & scrum to Russia',
  //       isCorrect: true,
  //     },
  //   ],
  //   verdict:
  //     'This was a legal pass so the try was awarded. 5 points to Ireland.',
  // },
  {
    difficulty: 2,
    tournament: '2019 Rugby World Cup',
    game: 'Australia vs Georgia',
    videoUrl: 'Medium-Australia-vs-Georgia.mp4',
    thumbnailUrl: '/images/Medium-Australia-vs-Georgia.png',
    pauseAt: 9.86,
    options: [
      {
        text: 'Illegal high challenge & yellow card',
        isCorrect: false,
      },
      {
        text: 'Knock on & 5 meter scrum',
        isCorrect: true,
      },
    ],
    verdict:
      'Ball was knocked forward by Australia. 5 meter scrum awarded to Georgia.',
  },
  {
    difficulty: 2,
    tournament: '2019 Rugby World Cup',
    game: 'Japan vs South Africa',
    videoUrl: 'Medium-Japan-vs-South-Africa.mp4',
    thumbnailUrl: '/images/Medium-Japan-vs-South-Africa.png',
    pauseAt: 9.9,
    options: [
      {
        text: 'Legal tackle & turnover ball to South Africa',
        isCorrect: false,
      },
      {
        text: 'Tackled above the horizontal & yellow card',
        isCorrect: true,
      },
    ],
    verdict:
      'Illegal challenge by South Africa, Japanese players leg lifted above the horizontal.',
  },
  {
    difficulty: 2,
    tournament: '2019 Rugby World Cup',
    game: 'Scotland vs Samoa',
    videoUrl: 'Medium-Scotland-vs-Samoa.mp4',
    thumbnailUrl: '/images/Medium-Scotland-vs-Samoa.png',
    pauseAt: 9.83,
    options: [
      {
        text: 'Knock on & 5 metre scrum',
        isCorrect: false,
      },
      {
        text: 'Penalty try & red card',
        isCorrect: true,
      },
    ],
    verdict:
      'Penalty try award to Scotland, Samoa tackled with knee`s first which is an illegal challenge.',
  },
  {
    difficulty: 2,
    tournament: '2019 Rugby World Cup',
    game: 'Wales vs Fiji',
    videoUrl: 'Medium-Wales-vs-Fiji.mp4',
    thumbnailUrl: '/images/Medium-Wales-vs-Fiji.png',
    pauseAt: 9.86,
    options: [
      {
        text: 'Try scored & 5 points to Fiji',
        isCorrect: false,
      },
      {
        text: 'Forward pass & penalty kick',
        isCorrect: true,
      },
    ],
    verdict:
      'Forward pass by Fiji, no try awarded.',
  },
  {
    difficulty: 3,
    tournament: '2019 Rugby World Cup',
    game: 'England vs New Zealand',
    videoUrl: 'Hard-England-vs-New-Zealand.mp4',
    thumbnailUrl: '/images/Hard-England-vs-New-Zealand.png',
    pauseAt: 9.9,
    options: [
      {
        text: 'Try & 5 points to England',
        isCorrect: false,
      },
      {
        text: 'Offside player off ball & penalty kick',
        isCorrect: true,
      },
    ],
    verdict:
      'Offside player initiates contact whiles obstructing play. Penalty at the place of infringement.',
  },
  {
    difficulty: 3,
    tournament: '2019 Rugby World Cup',
    game: 'Georgia vs Fiji',
    videoUrl: 'Hard-Georgia-vs-Fiji.mp4',
    thumbnailUrl: '/images/Hard-Georgia-vs-Fiji.png',
    pauseAt: 12.2,
    options: [
      {
        text: 'Ball held up & 5 metre scrum',
        isCorrect: false,
      },
      {
        text: 'Try scored & 5 points to Fiji',
        isCorrect: true,
      },
    ],
    verdict:
      'Try successfully grounded. 5 points to Fiji',
  },
  {
    difficulty: 3,
    tournament: '2019 Rugby World Cup',
    game: 'Wales vs Fiji',
    videoUrl: 'Hard-Wales-vs-Fiji.mp4',
    thumbnailUrl: '/images/Hard-Wales-vs-Fiji.png',
    pauseAt: 7.86,
    options: [
      {
        text: 'Player in touch & scrum Fiji',
        isCorrect: true,
      },
      {
        text: 'Try & 5 points to Wales',
        isCorrect: false,
      },
    ],
    verdict:
      'Welsh players foot went into touch (hits the white line), scrum awarded to Fiji.',
  },
];
