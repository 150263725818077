import { FC, useCallback, useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Button, ButtonTypes, ButtonWidths } from '@omm.agency/irecomponents';
import { AnimatePresence, motion, useAnimationControls } from "framer-motion";
import cx from 'classnames';
import { videoQuestions } from 'store/data';
import { useMount } from "react-use";
import _ from "underscore";

import s from './YourScore.module.scss';
import { useStore } from "store";
import { QuestionType } from "types/question";
import { PAGE_TRANSITION } from "durations";
import { delay } from "utils";

export const YourScore: FC = () => {
  const navigate = useNavigate();
  const [localScore, setLocalScore] = useState(0);
  const { questionNo, questions, answers, setAnswers, setQuestions, setQuestionNo, setScore } = useStore();

  // there does not seem to be a way to reference a specific animation in a map unless we do this
  // not ideal but thankfully we only have 3 items to deal with
  const questionInnerAnimation0 = useAnimationControls();
  const questionInnerAnimation1 = useAnimationControls();
  const questionInnerAnimation2 = useAnimationControls();
  const thumbnailOverlayAnimation0 = useAnimationControls();
  const thumbnailOverlayAnimation1 = useAnimationControls();
  const thumbnailOverlayAnimation2 = useAnimationControls();
  const thumbnailAnimation0 = useAnimationControls();
  const thumbnailAnimation1 = useAnimationControls();
  const thumbnailAnimation2 = useAnimationControls();

  useMount(() => {
    // gives us the ability to test this page without doing the quiz
    if(!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      if(answers.length === 0) {
        setAnswers([0,1,0]);
        setQuestionNo(2);
      }
      if(questions.length === 0) {
        setQuestions(_.sample(videoQuestions, 3));
      }
    }
  });

  useEffect(() => {
    if(answers.length !== 0) {
      setLocalScore(getScore())
    }
  }, [answers])

  const handleNext = () => {
    if ((questions.length - 1) === questionNo) {
      setScore(localScore)
      navigate('/thank-you');
    } else {
      navigate(`/video/${questionNo + 1}`);
    }
  }

  const questionVariants = {
    initial: {
      opacity: 0,
      x: '30%'
    },
    start: (custom: number) => ({
      x: 0,
      opacity: 1,
      transition: {
        ease: "easeOut",
        delay: custom * 0.1,
        duration: 0.45
      }
    })
  }

  const questionInnerVariants = {
    borderAnimated: (isCorrect: boolean) => ({
      borderStyle: 'solid',
      borderColor: isCorrect ? '#61C680' : '#DE4343',
      transition: {
        ease: "easeOut",
        duration: 0.45
      }
    }),
    borderStatic: (isCorrect: boolean) => ({
      borderStyle: 'solid',
      borderColor: isCorrect ? '#61C680' : '#DE4343',
      transition: {
        ease: "easeOut",
        duration: 0
      }
    })
  }

  const thumbnailOverlayVariants = {
    initial: {
      height: 0
    },
    expand: {
      height: '100%',
      transition: {
        ease: "easeOut",
        duration: 0.5
      }
    },
    hide: {
      opacity: 0,
      transition: {
        delay: 0.1,
        ease: "easeOut",
        duration: 0.35
      }
    }
  }

  const thumbnailVariants = {
    initial: {
      opacity: 0
    },
    show: {
      opacity: 1,
      transition: {
        duration: 0
      }
    }
  }

  const revealAnswerSequence = async (isCorrect: boolean, index: number, isAnimated: boolean) => {
    console.log('revealAnswerSequence - index, isCorrect, isAnimated', index, isCorrect, isAnimated)
    if (isAnimated) {
      await getThumbnailOverlayAnimation(index)!.start('expand');
      getThumbnailAnimation(index)!.start('show');
      getQuestionInnerAnimation(index)!.start(questionInnerVariants.borderAnimated(isCorrect));
      getThumbnailOverlayAnimation(index)!.start('hide');
    } else {
      getThumbnailAnimation(index)!.start('show');
      getQuestionInnerAnimation(index)!.start(questionInnerVariants.borderStatic(isCorrect));
    }
  }

  const handleQuestionAnimationComplete = async (index: number, isCorrect: boolean) => {
    await delay(500) // add some suspence
    if (index === (answers.length - 1)) {
      revealAnswerSequence(isCorrect, index, true);
    } else {
      // do not animate answers that have already been revealed
      revealAnswerSequence(isCorrect, index, false);
    }
  }

  const getQuestionInnerAnimation = (index: number) => {
    if (index === 0) return questionInnerAnimation0;
    if (index === 1) return questionInnerAnimation1;
    if (index === 2) return questionInnerAnimation2;
  }

  const getThumbnailOverlayAnimation = (index: number) => {
    if (index === 0) return thumbnailOverlayAnimation0;
    if (index === 1) return thumbnailOverlayAnimation1;
    if (index === 2) return thumbnailOverlayAnimation2;
  }

  const getThumbnailAnimation = (index: number) => {
    if (index === 0) return thumbnailAnimation0;
    if (index === 1) return thumbnailAnimation1;
    if (index === 2) return thumbnailAnimation2;
  }

  const renderQuestions = useCallback(() => {
    console.log('answers', answers);
    console.log('questions', questions.map((question: QuestionType) => question.options));

    return (
      questions.map((question: QuestionType, index: number) => {
        const backgroundImage = `url(${question.thumbnailUrl})`;

        const questionStyles = cx(
          s.question,
          { [s.isDone]: index <= questionNo }
        );

        const thumbnailOverlayStyles = cx(
          s.thumbnailOverlay,
          { [s.isCorrect]: index <= questionNo && questions[questionNo].options[answers[index]].isCorrect },
          { [s.isIncorrect]: index <= questionNo && !questions[questionNo].options[answers[index]].isCorrect }
        );

        return (
          <AnimatePresence key={index}>
            <motion.div
              custom={index}
              initial={questionVariants.initial}
              animate={questionVariants.start(index)}
              className={questionStyles}
              onAnimationComplete={() => {
                if (index <= questionNo) {
                  handleQuestionAnimationComplete(index, questions[questionNo].options[answers[index]].isCorrect)
                }
              }}
            >
              <motion.div animate={getQuestionInnerAnimation(index)} variants={questionInnerVariants} className={s.questionInner}>
                {
                  index <= questionNo ?
                    <>
                      <motion.div variants={thumbnailOverlayVariants} animate={getThumbnailOverlayAnimation(index)} className={thumbnailOverlayStyles}></motion.div>
                      <motion.div initial={thumbnailVariants.initial} variants={thumbnailVariants} animate={getThumbnailAnimation(index)} className={s.thumbnail} style={{ backgroundImage }}></motion.div>
                    </>
                    :
                    <>
                      <span className={s.nextLabel}>Next</span>
                      <h4>{question.game}</h4>
                    </>
                }
              </motion.div>
            </motion.div>
          </AnimatePresence>
        )
      })
    )
  }, [answers, questions]);

  const getScore = useCallback(() => {
    let count = 0;
    answers.map((answer: number, index: number) => {
      console.log(answer)
      if (questions[index].options[answer].isCorrect) {
        return count = count + 1;
      } 
    });
    return count;
  }, [answers, questions])

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ ease: "easeOut", duration: PAGE_TRANSITION / 1000 }}
      // onAnimationComplete={() => {setAnimatedIn(true)}}
      className={s.yourScoreWrap}
    >
      <h3 className={s.yourScoreValue}>{localScore}/{questionNo + 1}</h3>
      <h2>Your Score</h2>
      <motion.div className={s.questionsWrap}>
        {renderQuestions()}
      </motion.div>
      <Button type={ButtonTypes.SKILLS} width={ButtonWidths.NORMAL} content="Next" onClick={handleNext} className={s.button} isLarge />
    </motion.div>
  )
}
