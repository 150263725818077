import { FC, useState } from "react";
import { AppFrame, AppFrameBarPositionTypes, ConfirmPopup, Popup } from "@omm.agency/irecomponents";
import { useNavigate } from "react-router-dom";

import { useStore } from "../../store";

export const AppFrameLocal: FC = ({
  children
}) => {
  const { appFrameTitle, hideFrameBar, hideFrameBarTitle } = useStore();
  const navigate = useNavigate();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const handleOnClick = () => {
    setShowConfirmationModal(true);
  }

  return (
    <AppFrame frameBarPosition={AppFrameBarPositionTypes.TOP} title={appFrameTitle} exitLabel={'Exit'} onClick={handleOnClick} hideFrameBar={hideFrameBar} hideFrameBarTitle={hideFrameBarTitle}>
      {children}
      <Popup isVisible={showConfirmationModal} closePopup={() => setShowConfirmationModal(false)} onConfirm={() => {
        navigate('/');
        setShowConfirmationModal(false);
      }}>
        <ConfirmPopup title="ARE YOU SURE YOU WANT TO QUIT?" content={["The quiz hasn’t been completed. All points collected will be lost. "]} />
      </Popup>
    </AppFrame>
  )
}
