import { motion, useAnimationControls } from "framer-motion";
import { FC } from "react";
import { useMount } from "react-use";

import { TITLE_DELAY_DURATION } from "durations";
import s from '../VideoPage.module.scss';

interface BaseProps {
  tournament: string;
  game: string;
  handleVideoStart: () => void;
}

type StepTitleProps = BaseProps;

export const StepTitle: FC<StepTitleProps> = ({
  tournament,
  game,
  handleVideoStart
}) => {
  const videoOverlayAnimation = useAnimationControls();

  useMount(() => {
    videoOverlayAnimationSequence();
  });

  const videoOverlayAnimationSequence = async() => {
    await videoOverlayAnimation.start('start');
    await videoOverlayAnimation.start('exit');
    return handleVideoStart();
  }
  
  const videoOverlayVariants = {
    initial: {
      opacity: 0
    },
    start: {
      opacity: 1,
      transition: {
        ease: "easeOut",
        duration: 0.75
      }
    },
    exit: {
      opacity: 0,
      transition: {
        ease: "easeOut",
        delay: TITLE_DELAY_DURATION / 1000,
        duration: 0.75
      }
    }
  }

  return (
    <motion.div initial={videoOverlayVariants.initial} variants={videoOverlayVariants} animate={videoOverlayAnimation} className={s.videoTitleWrap}>
      <div className={s.videoOverlayWrap}>
        <p data-size-extra-large className={s.tournament}>{tournament}</p>
        <h2>{game}</h2>
      </div>
    </motion.div>
  )
}