import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { BackgroundOutlineText, Button, ButtonTypes, ButtonWidths } from "@omm.agency/irecomponents";

import s from './Error.module.scss';
import { PAGE_TRANSITION } from "durations";

export const Error: FC = () => {
  const navigate = useNavigate();
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ ease: 'easeOut', duration: PAGE_TRANSITION / 1000 }}
    >
      <BackgroundOutlineText className={s.bgText} isLarge content="404 Error" />
      <div className={s.wrap}>
        <motion.h1 data-size-large>404 Error</motion.h1>
        <p data-size-large>Something went wrong. Please refresh this page and try again.</p>
        <Button isLarge content="Refresh" onClick={() => { navigate('/') }} type={ButtonTypes.SKILLS} width={ButtonWidths.NORMAL} className={s.button} />
      </div>
    </motion.div>
  );
}
