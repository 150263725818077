import { FC } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Button, ButtonTypes, ButtonWidths, CrossIcon, TickIcon } from "@omm.agency/irecomponents";

import s from '../VideoPage.module.scss';

interface BaseProps {
  isCorrect: boolean;
  verdict: string;
  handleNext: () => void;
}

type StepAnswerProps = BaseProps;

export const StepAnswer: FC<StepAnswerProps> = ({
  isCorrect,
  verdict,
  handleNext,
}) => {
  return (
    <AnimatePresence>
      <motion.div 
        initial={{opacity: 0}} 
        animate={{opacity: 1}} 
        transition={{ease: "easeOut", duration: 0.3}} 
        className={s.videoTitleWrap} 
        onClick={handleNext}
      >
        <div className={s.videoOverlayWrap}>
          {
            isCorrect ? (
              <>
                <div className={s.answerIcon}><TickIcon width={60} height={60} color={'#61C680'} strokeWidth={2} /></div>
                <h1 className={s.answerHeader}>Your answer is correct</h1>
              </>
            )
            : 
            (
              <>
                <div className={s.answerIcon}><CrossIcon width={60} height={60} color={'#DE4343'} strokeWidth={2} /></div>
                <h1 className={s.answerHeader}>Your answer is incorrect</h1>
              </>
            )
          }
          <p data-size-large className={s.answerText}>{verdict}</p>
          <Button type={ButtonTypes.SKILLS} content="Next" onClick={handleNext} className={s.answerVideoOverlayButton} width={ButtonWidths.NORMAL} isLarge />
        </div>
      </motion.div>
    </AnimatePresence>
  )
}