import { FC, useEffect, useState } from "react";
import { BackgroundOutlineText, ReplayIcon } from "@omm.agency/irecomponents";
import { AnimatePresence, motion, useAnimationControls } from "framer-motion";

import { QuestionOptionType } from "types/question";
import s from '../VideoPage.module.scss';
import { backgroundOutlineTextVariants } from "animations/variants";
import { STEPS } from '../enums';
import { HEADER_DELAY } from "durations";
import { delay } from "utils";

interface BaseProps {
  step: number;
  selectedOption: number;
  questionOptions: QuestionOptionType[];
  isFirstVideoPlay: boolean;
  handleOptionClick: (index: number) => void;
  handleVideoReplay: () => void;
  onWhatsYourCallAnimationEnd: () => void;
}

type StepQuestionProps = BaseProps;

export const StepQuestion: FC<StepQuestionProps> = ({
  step,
  selectedOption,
  questionOptions,
  isFirstVideoPlay,
  handleOptionClick,
  handleVideoReplay,
  onWhatsYourCallAnimationEnd
}) => {
  const [optionClicked, setOptionClicked] = useState<boolean>(false);
  
  const backgroundOutlineTextAnimation = useAnimationControls();
  const whatsYourCallHeaderAnimation = useAnimationControls();
  const letsFindOutHeaderAnimation = useAnimationControls();
  const option0Animation = useAnimationControls();
  const option1Animation = useAnimationControls();
  const optionsOrAnimation = useAnimationControls();
  const videoReplayAnimation = useAnimationControls();

  useEffect(() => {
    if(step === STEPS.WHATS_YOUR_CALL) {
      whatsYourCallStartSequence()
    }
  }, [step])

  const whatsYourCallStartSequence = async() => {
    backgroundOutlineTextAnimation.start('start');
    whatsYourCallHeaderAnimation.start('start').then(whatsYourCallEndSequence);
  }

  const whatsYourCallEndSequence = async() => {
    whatsYourCallHeaderAnimation.start('end');
    backgroundOutlineTextAnimation.start('end').then(optionsSequence);
  }

  const optionsSequence = async() => {
    option0Animation.start('start');
    option1Animation.start(optionVariants.start(true));
    optionsOrAnimation.start({
      opacity: 1,
      transition: {
        duration: 0.45
      }
    });

    return await onWhatsYourCallAnimationEnd();
  }

  const letsFindOutSequence = async(index: number) => {
    whatsYourCallHeaderAnimation.start('exit');
    letsFindOutHeaderAnimation.start('enter');
    videoReplayAnimation.start('hide');

    option0Animation.start(index === 0 ? optionVariants.select(true) : 'hide');
    option1Animation.start(index === 0 ? 'hide' : optionVariants.select(false));
    
    optionsOrAnimation.start({
      opacity: 0,
      transition: {
        duration: 0.45
      }
    }).then(async() => {
      if(selectedOption < 0) { 
        await delay(1000);
        handleOptionClick(index);
      }
    })
  }

  const onOptionClick = (index: number) => {
    if(selectedOption < 0 && !optionClicked) {
      setOptionClicked(true);
      letsFindOutSequence(index);
    }
  }

  const whatsYourCallHeaderVariants = {
    initial: {
      opacity: 0,
      scale: 2.6,
      x: '150%',
      y: '550%',
    },
    start: {
      opacity: 1,
      x: '0',
      transition: {
        ease: "easeOut",
        duration: 1.1
      }
    },
    end: {
      scale: 1,
      y: '0',
      transition: {
        ease: "easeOut",
        delay: HEADER_DELAY / 1000,
        duration: 0.75
      }
    },
    exit: {
      opacity: 0,
      x: '-15%',
      transition: {
        ease: "easeOut",
        duration: 0.45
      }
    }
  }

  const letsFindOutHeader = {
    initial: {
      opacity: 0,
      x: '25%',
    },
    enter: {
      opacity: 1,
      x: '0',
      transition: {
        ease: "easeOut",
        duration: 0.45
      }
    }
  }

  const optionVariants = {
    initial: {
      y: '100%',
      opacity: 0
    },
    start: (hasDelay: boolean) => ({
      y: 0,
      opacity: 1,
      transition: {
        ease: "easeOut",
        delay: hasDelay ? 0.2 : 0,
        duration: 0.45
      }
    }),
    select: (isLeft: boolean) => ({
      x: isLeft ? '68%' : '-68%',
      transition: {
        ease: "easeOut",
        duration: 0.45
      }
    }),
    hide: {
      opacity: 0,
      transition: {
        ease: "easeOut",
        duration: 0.45
      }
    }
  }

  const videoReplayVariants ={
    hide: {
      opacity: 0,
      transition: {
        ease: "easeOut",
        duration: 0.45
      } 
    }
  }

  return (
    <AnimatePresence>
      <motion.div 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ease: "easeOut", duration: 0.5}}
        className={s.questionAnswerWrap}
      >
        <motion.div className={s.backgroundOutlineTextWrap} initial={backgroundOutlineTextVariants.initial} variants={backgroundOutlineTextVariants} animate={backgroundOutlineTextAnimation} style={{top: '100px'}}>
          <BackgroundOutlineText content="What's your call?" isLarge className={s.backgroundOutlineText} />
        </motion.div>
        <div className={s.questionAnswer}>
          <div className={s.quetsionAnswerHeader}>
            <motion.h2 initial={isFirstVideoPlay && whatsYourCallHeaderVariants.initial} variants={whatsYourCallHeaderVariants} animate={whatsYourCallHeaderAnimation}>What's your call?</motion.h2>
            <motion.h2 initial={letsFindOutHeader.initial} variants={letsFindOutHeader} animate={letsFindOutHeaderAnimation}>Let's find out!</motion.h2>
          </div>
          <div className={s.questionOptions}>
            <motion.div initial={isFirstVideoPlay && optionVariants.initial} animate={option0Animation} variants={optionVariants} className={s.questionOption}onClick={() => onOptionClick(0)}>
              <h4>Legal</h4>
              <p data-size-large>{questionOptions[0].text}</p>
            </motion.div>
            <motion.h3 initial={isFirstVideoPlay && {opacity: 0}} animate={optionsOrAnimation} className={s.questionOrLabel}>OR</motion.h3>
            <motion.div initial={isFirstVideoPlay && optionVariants.initial} animate={option1Animation} variants={optionVariants} className={s.questionOption} onClick={() => onOptionClick(1)}>
              <h4>Illegal</h4>
              <p data-size-large>{questionOptions[1].text}</p>
            </motion.div>
          </div>
        </div>
        <motion.div variants={videoReplayVariants} animate={videoReplayAnimation} className={s.videoReplay} onClick={handleVideoReplay}>
          <ReplayIcon width={18} height={18} color={'#fff'} />
          <p data-size-large>Replay</p>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  )
}