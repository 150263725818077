import { Button, ButtonTypes, Checkbox, Popup, ScrollFrame } from "@omm.agency/irecomponents";
import { LabelledCheckbox } from "components/LabelledCheckbox";
import { useState } from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { useStore } from "store";
import { motion } from "framer-motion";

import s from "./Terms.module.scss";
import { PAGE_TRANSITION } from "durations";
import { useMount } from "react-use";

export const Terms: FC = () => {
  const navigate = useNavigate();
  const { setHideFrameBar } = useStore();
  const [ageChecked, setAgeChecked] = useState<boolean>(false);

  useMount(() => {
    setHideFrameBar(false);
  });

  const checkboxHandler = useCallback((field: 'age') => {
    if (field === 'age') {
      setAgeChecked(!ageChecked)
    }
  }, [ageChecked, setAgeChecked]);

  const handleSubmit = () => {
    navigate('/how-to-play');
  }

  return <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    transition={{ ease: 'easeOut', duration: PAGE_TRANSITION / 1000 }}
  >
    <div className={s.infoBox}>
      <div className={s.termsForm}>
        <h2>Age Consent</h2>
        <div>
          <LabelledCheckbox checked={ageChecked} onChange={() => { checkboxHandler('age') }} text="I confirm that I’m at least 18 years old or have parental/guardian consent." />
        </div>
      </div>
      <Button className={s.button} isLarge content="Continue" type={ButtonTypes.SKILLS} disabled={!ageChecked} onClick={handleSubmit} />
    </div>
  </motion.div>;
}
