import { FC, useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { motion, useAnimationControls } from "framer-motion";
import { BackgroundOutlineText, Button, ButtonGroup, ButtonTypes, ButtonWidths, Popup } from '@omm.agency/irecomponents';

import s from './ThankYou.module.scss';
import { useStore } from "store";
import { backgroundOutlineTextVariants } from "animations/variants";
import { PAGE_TRANSITION } from "durations";
import { useMount } from "react-use";

export const ThankYou: FC = () => {
  const navigate = useNavigate();
  const { score, questions, setHideFrameBar, setHideFrameBarTitle } = useStore();
  const [animatedIn, setAnimatedIn] = useState<boolean>(false);

  const backgroundOutlineTextAnimation = useAnimationControls();
  const gameOverAnimation = useAnimationControls();

  useMount(() => {
    setHideFrameBarTitle(true)
  });

  useEffect(() => {
    if (animatedIn) {
      headerSequence();
    }
  }, [animatedIn])

  const headerSequence = async () => {
    gameOverAnimation.start('start');
    backgroundOutlineTextAnimation.start('start');
  }

  const handlePlayAgain = () => {
    navigate('/video/0');
  }

  const handleLeave = () => {
    setHideFrameBar(false);
    navigate('/sign-up');
  }

  const gameOverHeaderVariants = {
    intial: {
      x: '100%',
      opacity: 0
    },
    start: {
      x: 0,
      opacity: 1,
      transition: {
        ease: "easeOut",
        duration: 0.75
      }
    },
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ ease: "easeOut", duration: PAGE_TRANSITION / 1000 }}
      onAnimationComplete={() => setAnimatedIn(true)}
      className={s.gameOverWrap}
    >
      <motion.div style={{ width: 1200 }} initial={backgroundOutlineTextVariants.initial} variants={backgroundOutlineTextVariants} animate={backgroundOutlineTextAnimation}>
        <BackgroundOutlineText className={s.backgroundOutlineText} content="Thank you for playing" isLarge />
      </motion.div>
      <h3>You scored <span>{score} / {questions.length}</span></h3>
      <motion.h1 initial={gameOverHeaderVariants.intial} variants={gameOverHeaderVariants} animate={gameOverAnimation} data-size-large>Thank you<br />for playing</motion.h1>
      <p data-size-large>Congratulations on completing the Be The Referee quiz. Proceed to sign up to redeem your prize.</p>
      <ButtonGroup>
        <Button type={ButtonTypes.SECONDARY} content="Play Again" onClick={handlePlayAgain} width={ButtonWidths.NORMAL} isLarge />
        <Button type={ButtonTypes.SKILLS} content="Redeem Prize" onClick={handleLeave} width={ButtonWidths.NORMAL} isLarge />
      </ButtonGroup>
    </motion.div>
  )
}
