import {
  FC,
  Ref,
  SyntheticEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  ConfigContext,
  ConfigContextType,
  ProgressBarTypes,
  Video,
} from '@omm.agency/irecomponents';
import { AnimatePresence, motion, useAnimationControls } from 'framer-motion';

import s from '../VideoPage.module.scss';
import { STEPS } from '../enums';
import { useMount } from 'react-use';

interface BaseProps {
  step: number;
  videoSrc: string;
  videoRef: Ref<HTMLVideoElement>;
  handleOnPlaying: (e: SyntheticEvent<HTMLVideoElement>) => void;
  handleTimeUpdate: (e: SyntheticEvent<HTMLVideoElement>) => void;
  handleOnEnded: (e: SyntheticEvent<HTMLVideoElement>) => void;
}

type StepVideoProps = BaseProps;

export const StepVideo: FC<StepVideoProps> = ({
  step,
  videoSrc,
  videoRef,
  handleOnPlaying,
  handleTimeUpdate,
  handleOnEnded,
}) => {
  const videoAnimation = useAnimationControls();
  const { staticContentUrl } = useContext<ConfigContextType>(ConfigContext);
  const [uuid, setUuid] = useState('');

  useMount(() => {
    setUuid(crypto.randomUUID());
  });

  useEffect(() => {
    if (step === STEPS.WHATS_YOUR_CALL || step === STEPS.QUESTION) {
      videoAnimation.start('fadeOut');
    } else {
      videoAnimation.start('fadeIn');
    }
  }, [step]);

  const videoAnimationVariants = {
    initial: {
      opacity: 0,
    },
    fadeIn: {
      opacity: 1,
      transition: {
        ease: 'easeOut',
        duration: 0.3,
      },
    },
    fadeOut: {
      opacity: 0.6,
      transition: {
        ease: 'easeOut',
        duration: 0.3,
      },
    },
  };

  const getVideoSrc = useCallback(
    () => `${staticContentUrl}/${videoSrc}?id=${uuid}`,
    [staticContentUrl, uuid]
  );

  return (
    <AnimatePresence>
      <motion.div
        initial={videoAnimationVariants.initial}
        animate={videoAnimation}
        variants={videoAnimationVariants}
        className={s.videoWrap}
      >
        <Video
          ref={videoRef}
          src={getVideoSrc()}
          onPlaying={handleOnPlaying}
          onTimeUpdate={handleTimeUpdate}
          onEnded={handleOnEnded}
          videoControls={{
            controls: false,
            playsInline: true,
            autoPlay: false,
            loop: false,
            muted: true,
          }}
          hasProgressBar
          progressBarVariant={ProgressBarTypes.THIN_GREEN_GREY}
          className={s.video}
        />
      </motion.div>
    </AnimatePresence>
  );
};
