import create, { SetState, StateCreator } from 'zustand';
import { QuestionType } from '../types/question';

type State = {
  score: number;
  questionNo: number;
  questions: QuestionType[],
  hideFrameBar: boolean,
  appFrameTitle: string,
  answers: number[],
  hideFrameBarTitle: boolean,
  setScore: (score: number) => void;
  setQuestionNo: (questionNo: number) => void;
  setQuestions: (questions: QuestionType[]) => void;
  setHideFrameBar: (hide: boolean) => void;
  setAppFrameTitle: (appFrameTitle: string) => void;
  setAnswers: (answers: number[]) => void;
  setHideFrameBarTitle: (hideFrameBarTitle: boolean) => void;
};

const initialState = {
  score: 0,
  questionNo: 0,
  questions: [],
  hideFrameBar: true,
  appFrameTitle: '',
  answers: [],
  hideFrameBarTitle: false,
}

const stateCreator: StateCreator<State, SetState<State>> = (set, get) => ({
  ...initialState,
  setScore: (score) => set({ score }),
  setQuestionNo: (questionNo) => set({ questionNo }),
  setQuestions: (questions) => set({ questions }),
  setHideFrameBar: (hideFrameBar) => set({ hideFrameBar }),
  setAppFrameTitle: (appFrameTitle) => set({ appFrameTitle }),
  setAnswers: (answers) => set({ answers }),
  setHideFrameBarTitle: (hideFrameBarTitle) => set({ hideFrameBarTitle })
});

const useStore = create<State>(stateCreator);

const resetState = () => {
  useStore.setState(initialState);
} 

export {useStore, resetState};