import { BackgroundOutlineText } from "@omm.agency/irecomponents";
import { motion, useAnimationControls } from "framer-motion";
import { FC } from "react";
import cx from 'classnames';
import { useMount } from "react-use";

import { useStore } from "store";
import { backgroundOutlineTextVariants } from "animations/variants";
import s from '../VideoPage.module.scss';
import { HEADER_DELAY } from "durations";

interface BaseProps {
  onAnimationEnd: () => void;
}

type StepInitialProps = BaseProps;

export const StepInitial: FC<StepInitialProps> = ({
  onAnimationEnd
}) => {
  const {questionNo} = useStore();

  const headerAnimation = useAnimationControls();
  const backgroundOutlineTextAnimation = useAnimationControls();

  useMount(() => {
    headerAnimationSequence();
  });
  
  const headerAnimationSequence = async() => {
    headerAnimation.start('start');
    backgroundOutlineTextAnimation.start('start').then(videoOverlayAnimationSequence);
  }

  const videoOverlayAnimationSequence = async() => {
    headerAnimation.start('end');
    backgroundOutlineTextAnimation.start('end').then(onAnimationEnd);
  }

  const headerVariants = {
    initial: {
      x: '500px',
      opacity: 0,
    },
    start: {
      x: 0,
      opacity: 1,
      transition: {
        ease: "easeOut",
        duration: 1.1
      }
    },
    end: {
      opacity: 0,
      transition: {
        delay: HEADER_DELAY / 1000
      }
    }
  }

  const backgroundOutlineTextWrapStyles = cx(s.backgroundOutlineTextWrap, s.initial)

  return (
    <>    
      <motion.h1 data-size-large className={s.initialHeader} initial={headerVariants.initial} animate={headerAnimation} variants={headerVariants}>{`Challenge ${questionNo + 1}`}</motion.h1>
      <motion.div className={backgroundOutlineTextWrapStyles} initial={backgroundOutlineTextVariants.initial} animate={backgroundOutlineTextAnimation} variants={backgroundOutlineTextVariants}>
        <BackgroundOutlineText content={`Challenge ${questionNo + 1}`} isLarge className={s.backgroundOutlineText} />
      </motion.div>
    </>
  )
}