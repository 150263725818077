import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMount } from 'react-use';
import { motion, useAnimationControls } from 'framer-motion';
import _ from 'underscore';
import { BackgroundOutlineText, Button, ButtonTypes, ButtonWidths } from '@omm.agency/irecomponents';

import s from './HowToPlay.module.scss';
import { HEADER_DELAY, HOW_TO_PLAY_TIMER_DURATION, PAGE_TRANSITION } from 'durations';
import { useStore } from 'store';
import { videoQuestions } from 'store/data';
import { backgroundOutlineTextVariants } from "animations/variants";

interface contentItemType {
  title: string,
  text: string,
}

const content = [{
  title: 'WATCH THE CLIP',
  text: 'Watch footage from real, match moments where a referee was faced with making controversial decision.',
},
{
  title: 'ASSESS THE MOVE',
  text: 'Now it\'s time to put theory into practice. Carefully consider which of two given options is correct.',
},
{
  title: 'SEE THE VERDICT',
  text: 'Choose one of the two options from the list below…',
}
];

const DIFFICULTY = {
  EASY: 1,
  MEDIUM: 2,
  HARD: 3
};

export const HowToPlay: FC = () => {
  const navigate = useNavigate();
  const { setQuestions, setHideFrameBar } = useStore();
  const [animatedIn, setAnimatedIn] = useState<boolean>(false);
  const [playButtonIsDisabled, setPlayButtonIsDisabled] = useState<boolean>(true);

  const headerAnimation = useAnimationControls();
  const backgroundOutlineTextAnimation = useAnimationControls();
  const howToPlayAnimation = useAnimationControls();
  const howToPlayStepAnimation = useAnimationControls();
  const progressBarWrapAnimation = useAnimationControls();
  const progressBarAnimation = useAnimationControls();
  const bottomAnimation = useAnimationControls();

  useMount(() => {
    setHideFrameBar(false);
  });

  useEffect(() => {
    if (animatedIn) {
      headerAnimationSequence();
    }
  }, [animatedIn]);

  const headerAnimationSequence = async () => {
    headerAnimation.start('start');
    await backgroundOutlineTextAnimation.start('start').then(async () => {
      headerAnimation.start('end');
      await backgroundOutlineTextAnimation.start('end');
      return howToPlayAnimationSequence();
    });
  }

  const howToPlayAnimationSequence = async () => {
    bottomAnimation.start('fadeIn');
    return await howToPlayAnimation.start('start').then(async () => {
      progressBarAnimation.start('start');
      howToPlayStepAnimation.start('fadeIn');
    });
  }

  const exitAnimationSequence = async () => {
    headerAnimation.start('exit');
    howToPlayAnimation.start('exit');
    progressBarWrapAnimation.start('exit');
    bottomAnimation.start('exit');
  }

  const handlePlay = () => {
    const easyQuestions = videoQuestions.filter((item) => item.difficulty === DIFFICULTY.EASY);
    const mediumQuestions = videoQuestions.filter((item) => item.difficulty === DIFFICULTY.MEDIUM);
    const hardQuestions = videoQuestions.filter((item) => item.difficulty === DIFFICULTY.HARD);

    const sampledQuestions = [
      ..._.sample(easyQuestions, 1),
      ..._.sample(mediumQuestions, 1),
      ..._.sample(hardQuestions, 1)
    ];

    console.info(sampledQuestions);

    setQuestions(sampledQuestions);

    exitAnimationSequence().then(() => {
      navigate('/video/0');
    });
  }

  const renderHowToPlayStep = (item: contentItemType, i: number) => {
    return (
      <motion.div variants={howToPlayStepVariants} animate={howToPlayStepAnimation} className={s.howToPlayStep} key={i} custom={i}>
        <span className={s.step}>{i + 1}</span>
        <h4>{item.title}</h4>
        <p>{item.text}</p>
      </motion.div>
    )
  }

  const headerVariants = {
    initial: {
      opacity: 0,
      scale: 3,
      x: '150%',
      y: '520%',
    },
    start: {
      opacity: 1,
      x: '0',
      transition: {
        ease: "easeOut",
        duration: 1.1
      }
    },
    end: {
      scale: 1,
      y: '0',
      transition: {
        delay: HEADER_DELAY / 1000,
        ease: "easeOut",
        duration: 0.75
      }
    },
    exit: {
      x: '-75%',
      opacity: 0,
      transition: {
        ease: "easeOut",
        duration: 0.75
      }
    }
  };

  const howToPlayVariants = {
    intial: {
      x: '100%',
      opacity: 0
    },
    start: {
      x: 0,
      opacity: 1,
      transition: {
        ease: "easeOut",
        duration: 0.4
      }
    },
    exit: {
      x: '-75%',
      opacity: 0,
      transition: {
        ease: "easeOut",
        duration: 0.75
      }
    }
  }

  const howToPlayStepVariants = {
    fadeIn: (custom: number) => ({
      opacity: 1,
      transition: {
        delay: custom * (HOW_TO_PLAY_TIMER_DURATION / (HOW_TO_PLAY_TIMER_DURATION / 2)) + 0.25,
        duration: 0.25
      }
    })
  }

  const progressBarWrapVariants = {
    exit: {
      x: '-15%',
      transition: {
        ease: "easeOut",
        duration: 0.75
      }
    }
  }

  const progressBarVariants = {
    initial: {
      x: '-100%'
    },
    start: {
      x: 0,
      transition: {
        duration: (HOW_TO_PLAY_TIMER_DURATION / 1000),
        ease: "linear"
      }
    }
  }

  const bottomVariants = {
    initial: {
      opacity: 0
    },
    fadeIn: {
      opacity: 1,
      transition: {
        ease: "easeOut",
        duration: 0.4
      }
    },
    exit: {
      opacity: 0,
      transition: {
        ease: "easeOut",
        duration: 0.75
      }
    }
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ ease: "easeOut", duration: PAGE_TRANSITION / 1000 }}
      onAnimationComplete={() => setAnimatedIn(true)}
      className={s.howToPlayWrap}
    >
      <motion.h2 initial={headerVariants.initial} variants={headerVariants} animate={headerAnimation} className={s.headerWrap}>How to play</motion.h2>
      <motion.div style={{ width: 1700 }} initial={backgroundOutlineTextVariants.initial} variants={backgroundOutlineTextVariants} animate={backgroundOutlineTextAnimation}>
        <BackgroundOutlineText content='How to play' isLarge className={s.backgroundOutlineText} />
      </motion.div>
      <motion.div variants={howToPlayVariants} animate={howToPlayAnimation}
        initial={howToPlayVariants.intial}
        className={s.howToPlaySteps}
      >
        <div className={s.howToPlayStepsInner}>
          {
            content.map((item: contentItemType, i: number) => renderHowToPlayStep(item, i))
          }
        </div>
      </motion.div>
      <motion.div className={s.howToPlayBottomWrap} variants={bottomVariants} initial={bottomVariants.initial} animate={bottomAnimation}>
        <motion.div variants={progressBarWrapVariants} animate={progressBarWrapAnimation} className={s.howToPlayProgressWrap}>
          <motion.div initial={progressBarVariants.initial} variants={progressBarVariants} animate={progressBarAnimation} className={s.howToPlayProgressBar} onAnimationComplete={() => setPlayButtonIsDisabled(false)}></motion.div>
        </motion.div>
        <Button type={ButtonTypes.SKILLS} width={ButtonWidths.NORMAL} content="Play" onClick={handlePlay} disabled={playButtonIsDisabled} className={s.button} isLarge />
      </motion.div>
    </motion.div>
  )
}
